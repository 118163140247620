<div class="modal-dialog modal-lg modal-primary" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Help & Support</h4>
      <button type="button" class="close" (click)="this.CloseModal.emit()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div id="hubspotForm"></div>
    </div>
  </div>
</div>

