<div class="animated fadeIn">
  <div
    class="d-flex justify-content-center my-5"
    *ngIf="loading; else showPage"
  >
    <div class="sk-circle-fade">
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
      <div class="sk-circle-fade-dot"></div>
    </div>
  </div>

  <ng-template #showPage class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto mt-0 mt-md-4">
            <div class="card mx-4">
              <div class="card-body p-4">
                <form
                  novalidate
                  (ngSubmit)="submitApplication()"
                  class="needs-validation form-horizontal"
                  [formGroup]="organizationApplicationForm"
                >
                  <h1>Register your organization</h1>
                  <p class="text-muted pl-1">
                    You will be notiied once your application is approved
                  </p>

                  <div>
                    <div class="row">
                      <!-- Row for Organization name and short name -->

                      <div class="col-12 col-md-6">
                        <label for="orgName" class="col-form-label"
                          >Organization Name</label
                        >
                        <input
                          type="text"
                          placeholder="eg. Medicines for Malaria Ventures"
                          class="form-control"
                          id="orgName"
                          formControlName="name"
                          [ngClass]="{
                            'is-valid': orgName.valid && orgName.touched,
                            'is-invalid': orgName.touched && !orgName.valid
                          }"
                        />

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="orgName.errors"
                        >
                          <p *ngIf="orgName.errors.required">
                            Please input your organization's name
                          </p>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <label for="short-name" class="col-form-label"
                          >Short Name</label
                        >
                        <input
                          type="text"
                          placeholder="eg. MMV"
                          class="form-control"
                          id="short-name"
                          formControlName="shortName"
                          [ngClass]="{
                            'is-valid': shortName.valid && shortName.touched,
                            'is-invalid': shortName.touched && !shortName.valid
                          }"
                        />

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="shortName.errors"
                        >
                          <p *ngIf="shortName.errors.required">
                            Please input your organization's short name
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- /row for org name and short name -->

                    <div class="row">
                      <!-- col for org website and country input -->
                      <div class="col-12 col-md-6">
                        <label for="web-domain" class="col-form-label"
                          >Organization Website</label
                        >
                        <input
                          type="url"
                          class="form-control"
                          id="web-domain"
                          formControlName="website"
                          placeholder="eg. mmv.org"
                          [ngClass]="{
                            'is-valid': website.valid && website.touched,
                            'is-invalid': !website.valid && website.touched
                          }"
                        />
                        <span class="help-block text-muted pl-1">
                          Do not include <b>https://</b> or <b>www.</b>
                        </span>

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="website.errors"
                        >
                          <!-- invalid feedback for country -->
                          <div *ngIf="website.errors?.required">
                            Please input your organizations domain
                          </div>

                          <div *ngIf="website.errors?.forbiddenName">
                            Please enter <b>Only</b> your domain eg. who.org
                          </div>

                          <div *ngIf="website.errors?.domainTaken">
                            An organization with this domain already exists
                          </div>
                          <div *ngIf="website.errors?.domainPending">
                            This organization has a pending application
                          </div>
                        </div>
                        <!-- end invalid feedback -->
                      </div>
                      <!-- /col for org website -->

                      <div class="col-12 col-md-6">
                        <!-- col for country input -->
                        <label class="col-form-label" for="country"
                          >Country</label
                        >
                        <fieldset>
                          <ng-select
                            [items]="countries"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [searchable]="true"
                            [placeholder]="countryPlaceholder"
                            (close)="getStates()"
                            id="country"
                            formControlName="country"
                            dropdownPosition="bottom"
                            bindLabel="name"
                            required
                            loadingText="Loading..."
                            [ngClass]="{
                              'is-valid': country.valid && country.touched,
                              'is-invalid': !country.valid && country.touched
                            }"
                          >
                          </ng-select>
                          <!-- get cities in country for city dropdown when clicked -->
                        </fieldset>

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="country.errors"
                        >
                          <!-- invalid feedback for country -->
                          <div *ngIf="country.errors.required">
                            <!-- selected country required error -->
                            Please select a country
                          </div>
                        </div>
                        <!-- end invalid feedback -->
                      </div>
                      <!-- /col for country -->
                    </div>
                    <!-- end row -->

                    <div class="row">
                      <!-- row for state and city input -->
                      <div class="col-md-6" [hidden]="hideStatesInput">
                        <label class="col-form-label" for="state">State</label>
                        <fieldset class="form-group">
                          <ng-select
                            [items]="states"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [searchable]="true"
                            [loading]="citiesLoading"
                            [placeholder]="statePlaceholder"
                            (close)="getCitiesOfState()"
                            dropdownPosition="bottom"
                            id="state"
                            formControlName="state"
                            bindLabel="name"
                            class="bootstrap"
                            loadingText="loading..."
                            required
                            [ngClass]="{
                              'is-valid': state.valid && state.touched,
                              'is-invalid': !state.valid && state.touched
                            }"
                          >
                          </ng-select>
                        </fieldset>

                        <!-- invalid feedback for country -->
                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="state.errors"
                        >
                          <div *ngIf="state.errors.required">
                            <!-- selected city required error -->
                            Please select a state
                          </div>
                        </div>
                        <!-- end invalid feedback -->
                      </div>
                      <!-- /col for states input -->

                      <div class="col-md-6" [hidden]="hideCitiesInput">
                        <label class="col-form-label" for="city">City</label>
                        <fieldset class="form-group">
                          <ng-select
                            [items]="cities"
                            [multiple]="false"
                            [closeOnSelect]="true"
                            [searchable]="true"
                            [loading]="citiesLoading"
                            [placeholder]="cityPlaceholder"
                            dropdownPosition="bottom"
                            id="city"
                            formControlName="city"
                            bindLabel="name"
                            class="bootstrap"
                            loadingText="loading..."
                            required
                            [ngClass]="{
                              'is-valid': city.valid && city.touched,
                              'is-invalid': !city.valid && city.touched
                            }"
                          >
                          </ng-select>
                        </fieldset>

                        <!-- invalid feedback for country -->
                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="city.errors"
                        >
                          <div *ngIf="city.errors.required">
                            <!-- selected city required error -->
                            Please select a city
                          </div>
                        </div>
                        <!-- end invalid feedback -->
                      </div>
                      <!-- /col for city input -->
                    </div>
                    <!-- /row for city and state -->

                    <div class="row">
                      <div class="col-12 col-md-6">
                        <label for="address1" class="col-form-label"
                          >Address Line 1</label
                        >
                        <input
                          type="text"
                          placeholder="eg. 100 example rd"
                          class="form-control"
                          id="address1"
                          formControlName="address1"
                          [ngClass]="{
                            'is-valid': address1.valid && address1.touched,
                            'is-invalid': !address1.valid && address1.touched
                          }"
                        />

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="address1.errors"
                        >
                          <div *ngIf="address1.errors.required">
                            <!-- selected city required error -->
                            Please enter your address
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <label for="address2" class="col-form-label"
                          >Address Line 2</label
                        >
                        <input
                          type="text"
                          placeholder="eg. apt. 001"
                          class="form-control"
                          id="address2"
                          formControlName="address2"
                        />
                      </div>
                    </div>
                    <!-- /address lines -->

                    <div class="row">
                      <div class="col-12 col-md-6">
                        <label for="postalCode" class="col-form-label"
                          >Postal Code</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="postalCode"
                          formControlName="postalCode"
                          [ngClass]="{
                            'is-valid': postalCode.valid && postalCode.touched,
                            'is-invalid':
                              !postalCode.valid && postalCode.touched
                          }"
                        />

                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="postalCode.errors"
                        >
                          <div *ngIf="postalCode.errors.required">
                            <!-- selected city required error -->
                            Please enter your postal code
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <label for="orgAdminEmail">Organization Admin</label>
                        <input
                          type="text"
                          class="form-control"
                          id="orgAdminEmail"
                          formControlName="orgAdminEmail"
                          [ngClass]="{
                            'is-valid':
                              orgAdminEmail.touched &&
                              orgAdminEmail.valid &&
                              !organizationApplicationForm?.errors
                                ?.domainMismatch,
                            'is-invalid':
                              orgAdminEmail.touched &&
                              !!organizationApplicationForm?.errors
                                ?.domainMismatch
                          }"
                        />

                        <span class="help-block text-muted pl-1">
                          Please ensure that you have the permission to submit
                          this application and nominate an admin.
                        </span>
                        <!-- invalid feedback for org admin email -->
                        <div
                          class="invalid-feedback"
                          style="color: orangered"
                          *ngIf="organizationApplicationForm.errors"
                        >
                          <div *ngIf="orgAdminEmail?.errors?.required">
                            <!-- selected city required error -->
                            Please enter the email of the organizations admin.
                          </div>

                          <div
                            *ngIf="
                              organizationApplicationForm.errors.domainMismatch
                            "
                          >
                            The email and website name you have entered must
                            match
                          </div>
                        </div>
                        <!-- end invalid feedback -->
                      </div>
                    </div>
                    <!-- End Row -->

                    <div class="form-actions mt-4">
                      <button
                        type="submit"
                        class="btn btn-primary mr-1"
                        [disabled]="
                          organizationApplicationForm.pristine ||
                          organizationApplicationForm.invalid
                        "
                      >
                        <span>Submit</span>
                      </button>
                      <button
                        (click)="organizationApplicationForm.reset()"
                        class="btn btn-secondary"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <!--/ organization details form group-->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </ng-template>
</div>

<!-- end animate fade in -->
