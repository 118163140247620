import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IDocs } from '../views/settings/admin/admin.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  // toastr
  private toasterService: ToasterService;

  constructor(
    private afs: AngularFirestore,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  /**
   * Retrieves the terms of service and privacy policy from firestore and returns the observable
   */
  async getLegalDocuments() {
    return this.afs.doc<IDocs>('licenses/GHSS').valueChanges();
  }

  /**
   * Updates the terms of service and privacy policy on firestore then displays a toast
   * @param formData The values to update the privacy policy and terms of service to
   */
  async updateLegalDocuments(formData) {
    try {
      await this.afs.doc('licenses/GHSS').update(
        {
          termsOfService: formData.termsOfService,
          privacyPolicy: formData.privacyPolicy,
        }
      );

      this.showToast('success', 'success', `Licences updated`);

    } catch (e) {
      this.showToast('error', 'error updating licenses', `${e.code}\n${e.message}`);
    }
  }


  /**
   * Displays a toast message
   * @param type the type of toast to display, success/error/waning/info/primary
   * @param title the title of the toast
   * @param body the message body of the toast
   */
  showToast(type, title: string, body: string) {
    this.toasterService.pop(type, title, body);
  }


}
