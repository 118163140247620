<ng-template #showLicense class="modal-dialog modal-lg" role="document">
	<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title">{{ orgName }}</h4>
			<button type="button" class="close" (click)="closeModal()" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body" [innerHTML]="license"></div>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary" (click)="closeModal()">Back</button>
		</div>
	</div>
	<!-- /.modal-content --> </ng-template
><!-- /.modal-dialog -->

<div class="d-flex justify-content-center my-5" *ngIf="loading; else showLicense">
	<div class="sk-circle-fade">
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
		<div class="sk-circle-fade-dot"></div>
	</div>
</div>
