import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
// Add the Performance Monitoring library
import 'firebase/performance';
import 'firebase/analytics';
import {ToasterConfig, ToasterService} from 'angular2-toaster';


@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<toaster-container [toasterconfig]="toasterconfig"></toaster-container><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  // toastr
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 5000
    });

  constructor(private router: Router, toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
