<app-header
	[asideMenuToggler]="false"
	[mobileAsideMenuToggler]="false"
	[navbarBrandRouterLink]="['/dashboard']"
	[fixed]="true"
	[navbarBrandFull]="{
		src: 'https://www.pharos.community/one-page/images/pharos_logo.png',
		width: 150,

		alt: 'Pharos Logo'
	}"
	[navbarBrandMinimized]="{
		src: 'https://www.pharos.community/one-page/images/pharos_logo.png',
		width: 30,
		height: 30,
		alt: 'Pharos Logo'
	}"
	[sidebarToggler]="'lg'"
	*ngIf="auth.user$ | async as user"
>
	<!--  LEFT SIDE LIST -->
	<ul class="nav navbar-nav d-md-down-none text-nav-bar">
		<li class="nav-item px-3">
			<a *ngIf="user.permissions.candidate" class="nav-link" routerLink="/dashboard">Dashboard</a>
		</li>
		<li class="nav-item px-3">
			<a class="nav-link" href="https://pharos.community">About</a>
		</li>
		<li class="nav-item px-3" style="cursor: pointer">
			<a class="nav-link" (click)="supportModal.show()">Help & Support</a>
		</li>
	</ul>

	<!--  RIGHT SIDE LIST -->

	<ul class="nav navbar-nav ml-auto" style="padding-right: 15px">
		<li class="nav-item d-md-down-none" style="cursor: pointer">
			<!-- BELL NOTIFICATIONS -->
			<a class="nav-link"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">0</span></a>
		</li>

		<li class="nav-item dropdown ml-3" dropdown placement="bottom right">
			<a
				class="nav-link"
				data-toggle="dropdown"
				role="button"
				aria-haspopup="true"
				aria-expanded="false"
				dropdownToggle
			>
				<!--        Section for account dropdown-->

				<div class="mr-2" style="cursor: pointer">
					<span>Account</span>
					<i class="icon-arrow-down px-1"></i>
				</div>
			</a>

			<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
				<a class="dropdown-item" routerLink="/settings/profile"><i class="icon-settings"></i>Settings</a>
				<a class="dropdown-item" routerLink="/forgot-password"><i class="fa fa-user"></i>Reset Password</a>
				<a class="dropdown-item" (click)="auth.signOut()" style="cursor: pointer"
					><i class="icon-logout"></i>Sign Out</a
				>
			</div>
		</li>
	</ul>
</app-header>

<div class="app-body">
	<app-sidebar
		#appSidebar
		[fixed]="true"
		[display]="'lg'"
		[minimized]="sidebarMinimized"
		(minimizedChange)="toggleMinimize($event)"
	>
		<app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
		<app-sidebar-minimizer></app-sidebar-minimizer>
	</app-sidebar>

	<!-- Main content -->
	<main class="main">
		<div class="container-fluid p-0">
			<!-- Modal for extra information when user clicks on a project -->
			<div
				bsModal
				#supportModal="bs-modal"
				class="modal fade"
				tabindex="-1"
				role="dialog"
				aria-labelledby="supportModal"
				aria-hidden="true"
			>
				<app-support-modal (CloseModal)="supportModal.hide()"></app-support-modal>
			</div>
			<router-outlet></router-outlet>
		</div>
		<!-- /.container-fluid -->
	</main>
</div>
<app-footer></app-footer>
