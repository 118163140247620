import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { debounceTime, map, take } from 'rxjs';

/** A Organization's domain name can't include https:// http:// or www. */
export function domainNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const domain = control.value;
    return domain?.startsWith('https://') ||
      domain?.startsWith('http://') ||
      domain?.startsWith('www')
      ? { forbiddenName: { value: control.value } }
      : null;
  };
}

/** A Organization's domain name can't include https:// http:// or www. */
export function orgAdminEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const orgDomain: string = control.get('website').value;
    const adminDomain = control.get('orgAdminEmail')?.value?.split('@')[1];
    return adminDomain !== orgDomain
      ? { domainMismatch: { value: control.value } }
      : null;
  };
}

export function pendingDomainAvailability(afs: AngularFirestore) {
  return (control: AbstractControl) => {
    const domain = control.value.toLowerCase();
    return afs
      .collection('organizationApplications', (ref) =>
        ref.where('website', '==', domain)
      )
      .valueChanges() //  observable array of users that match the given email
      .pipe(
        debounceTime(500),
        take(1), // choose only the first element in the observable array
        // if array isn't empty return map -> domainTaken: true means domain already exists in db
        // otherwise return null meaning domain is not in db and the input is valid
        map((arr) => (arr.length ? { domainPending: true } : null))
      );
  };
}

export function domainAvailability(afs: AngularFirestore) {
  // params:AngularFirestore. returns a dict of string:any or null
  return (control: AbstractControl) => {
    const domain = control.value.toLowerCase();

    return afs
      .collection('organizations', (ref) => ref.where('website', '==', domain))
      .valueChanges() //  observable array of users that match the given email
      .pipe(
        debounceTime(500),
        take(1), // choose only the first element in the observable array
        // if array isn't empty return map -> domainTaken: true means domain already exists in db
        // otherwise return null meaning domain is not in db and the input is valid
        map((arr) => (arr.length ? { domainTaken: true } : null))
      );
  };
}
