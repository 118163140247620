import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { SupervisorStatus, User } from '../interfaces/user-interface';
import { OrganizationInterface } from '../interfaces/organization-interface';
import firebase from 'firebase/compat/app';

declare let pendo: any;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private actionConfig = {
		url: 'https://ghss-ng.firebaseapp.com/',
		handleCodeInApp: false,
	};
	// toastr
	private toasterService: ToasterService;

	user$: Observable<User>; // defined as observable as it can change when user signs in/out. type: User from the model

	userCredential; // to store the promise returned when signing up/ in with email & password

	constructor(
		// inject imports for fire store auth service in constructor
		private afAuth: AngularFireAuth,
		private afs: AngularFirestore,
		private router: Router,
		toasterService: ToasterService
	) {
		this.toasterService = toasterService;

		/** Obtain currently logged in user data **/

		// Get the auth state, then fetch the Firestore user document or return null
		this.user$ = this.afAuth.authState.pipe(
			// define the observable state
			switchMap((user) => {
				// Logged in
				if (user) {
					// if user is defined
					// point to document with matching ID
					pendo.initialize({
						visitor: {
							id: `${user.uid}`,
							email: user.email, // Recommended if using Pendo Feedback, or NPS Email
							// full_name:   // Recommended if using Pendo Feedback
							// TODO: CHANGE ROLES TO CUSTOM CLAIMS AND INCLUDE HERE
							// role:         // Optional
						},
						account: {
							id: user.uid,
							// name:         // Optional
							// is_paying:    // Recommended if using Pendo Feedback
							// monthly_value:// Recommended if using Pendo Feedback
							// planLevel:    // Optional
							// planPrice:    // Optional
							// creationDate: // Optional
						},
					});
					return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
				} else {
					// Logged out
					return of(null); // allows us to tell when user is not logged in
				}
			})
		);
	}

	/** Role-based Authorization Section **/

	/**
	 * Boolean function to determine whether or not the user has the requested permissions
	 * @param {User} user
	 * @param {string[]} allowedRoles
	 * @return boolean: whether or not user has the requested permissions
	 */
	private static checkAuthorization(user: User, allowedRoles: string[]): boolean {
		for (const role of allowedRoles) {
			if (user.permissions[role]) {
				return true;
			}
		} // if the role is not within the logged in users permissions then
		return false;
	}

	/**
	 * Displays a toast message
	 * @param type the type of toast to display, success/error/waning/info/primary
	 * @param title the title of the toast
	 * @param body the message body of the toast
	 */
	showToast(type, title: string, body: string) {
		this.toasterService.pop(type, title, body);
	}

	/**
	 * determines if the user has candidate permissions
	 * @param user: currently signed in user data
	 */
	isCandidate(user: User): boolean {
		const allowed = ['candidate', 'supervisor', 'organizationAdmin', 'superAdmin'];
		return AuthService.checkAuthorization(user, allowed);
	}

	/**
	 * determines if the user has Supervisor permissions
	 * @param user: currently signed in user data
	 */
	isSupervisor(user: User): boolean {
		const allowed = ['supervisor', 'organizationAdmin', 'superAdmin'];
		return AuthService.checkAuthorization(user, allowed);
	}

	/**
	 * determines if the user has Organization Admin permissions
	 * @param user: currently signed in user data
	 */
	isOrgAdmin(user: User): boolean {
		const allowed = ['organizationAdmin', 'superAdmin'];
		return AuthService.checkAuthorization(user, allowed);
	}

	/**
	 * determines if the user has Super Admin permissions
	 * @param user: currently signed in user data
	 */
	isSuperAdmin(user: User): boolean {
		const allowed = ['superAdmin'];
		return AuthService.checkAuthorization(user, allowed);
	}

	/**
	 * Function to get the currently signed in user and associated data.
	 **/
	async getUserData() {
		return this.user$.pipe(first()).toPromise();
		// gets the first obj in user$ observable
	}

	/**
	 * Retrieves an observable list of users [should be only 1 doc] which have an email property == the given email
	 * @param email Email used to search the database
	 */
	async getUserDataByEmail(email: string) {
		return this.afs
			.collection('users', (ref) => {
				return ref.where('email', '==', email);
			})
			.valueChanges();
	}

	/**
	 * Sign users in with email and password
	 *  @param  {String} email: Email captured from form
	 *  @param  {String} password: Password captured from form
	 *  **/
	async emailSignIn(email, password) {
		// FUNCTION FOR SIGNING IN USERS WITH EMAIL & PASSWORD

		try {
			this.userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);

			const userData = await this.getUserData();
			const user = await this.afAuth.currentUser;
			this.showToast('success', `Signed In`, `Signed in as ${userData.username}`);
			if (!user.emailVerified) {
				this.showToast('warning', `Email not verified`, `Please check your inbox for the verification link`);
			}
			await this.router.navigate(['/dashboard']);
		} catch (err) {
			if (err.code === 'auth/wrong-password') {
				console.log(`Error Code: ${err.code} \n Error Message: ${err.message}`);
				this.showToast('error', `Error signing in`, `The password you entered is incorrect`);
			} else {
				console.log(`Error Code: ${err.code} \n Error Message: ${err.message}`);
				this.showToast('error', `${err.code}`, `${err.message}`);
			}
		}
	}

	/** Register users with email and password
	 *  @param  {String} email: Email captured from form
	 *  @param  {String} password: Password captured from form
	 *  @param  {String} username: Username captured from form
	 **/
	async emailRegistration(
		email: string,
		password: string,
		username: string,
		organization: Partial<OrganizationInterface>
	) {
		try {
			this.userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
		} catch (err) {
			this.showToast('error', `${err.code}`, `${err.message}`);
			console.log(`Error Code: ${err.code}\nError Message: ${err.message}`);
		}

		try {
			await this.insertNewUser(this.userCredential.user, username, organization);
			await this.router.navigate(['/settings']);
		} catch (err) {
			console.log(`Login Error: ${err.code}\n${err.message}`);
			this.showToast('error', `${err.message}`, `Error inserting user into database`);
		}
	}

	/**
	 * Insert data of newly registered user to firestore
	 * @param  {String} user: The user object obtained from auth
	 * @param  {String} username: The username obtained from the register form
	 **/
	private async insertNewUser(user: firebase.User, username: string, organization: Partial<OrganizationInterface>) {
		await this.verifyEmail(); // send email verification before inserting data
		// Sets user data to firestore on login
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

		const data = {
			activeStatus: false,
			biography: '',
			banned: false,
			email: user.email,
			firstName: '',
			lastName: '',
			linkedIn: '',
			city: { name: '' },
			country: {
				name: '',
				flag: '',
				phoneCode: '',
				isoCode: '',
				latitude: '',
				longitude: '',
				currency: '',
			},
			organization: { name: organization.name, ref: organization.id },
			supervisor: { name: '', ref: '', email: '', status: SupervisorStatus.none },
			permissions: {
				candidate: false,
				organizationAdmin: false,
				superAdmin: false,
				supervisor: false,
			},
			phoneNumber: 0,
			projectsApplied: [],
			ownedProjects: [],
			topSkills: [],
			roles: [],
			uid: user.uid,
			username: username,
		};

		// update admin permissions and references if the registrant is the pending admin of their org
		if (!organization?.admin && user.email == organization?.pendingAdmin) {
			data.permissions.organizationAdmin = true;
			data.permissions.supervisor = true;
			data.supervisor = {
				name: username,
				email: user.email,
				ref: user.uid,
				status: SupervisorStatus.approved,
			};

			const orgRef: AngularFirestoreDocument = this.afs.doc(`organizations/${organization.id}`);

			// this adds the authorized admin and removes pending admin field.
			await orgRef.update({
				admin: {
					name: username,
					ref: user.uid,
				},
				pendingAdmin: firebase.firestore.FieldValue.delete(),
			});
		}

		return userRef.set(data, { merge: true }); // merge stops destructive operation
	}

	/** Sign out users from auth service and re route to login page **/
	async signOut() {
		await this.afAuth.signOut(); // auth.signOut();
		await this.router.navigate(['/login']);
		this.showToast('info', 'Signed Out', `Successfully signed out`);
	}

	/** Send an email which verifies the users email address **/
	async verifyEmail() {
		try {
			const user = await this.afAuth.currentUser;
			await user.sendEmailVerification(this.actionConfig);
			this.showToast('info', 'Email Verification', `Please check your inbox for ${user.email}`);
		} catch (error) {
			this.showToast('error', 'Error sending email verification', `${error.message}`);
			console.log(`Error Code:${error.code}\nError Message:${error.message}`);
		}
	}
}
