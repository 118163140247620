import {Injectable} from '@angular/core';
import {
  CanActivate, CanActivateChild, CanDeactivate,
  CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {map, take, tap} from 'rxjs/operators';
import {ToasterService} from 'angular2-toaster';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  // toastr
  private toasterService: ToasterService;

  constructor(
    private auth: AuthService,
    private router: Router,
    toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  // TODO: Add route guards for specific routes like settings/admin or settings/organization
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.user$.pipe(
      take(1),
      map(user => !!user),  // map to boolean
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['/login']).then(r =>
            console.log('access denied.'));
          this.toasterService.pop('error', 'Access Denied', 'Please login first');
        } else { // check if user has been banned
          this.auth.getUserData().then(user => {
              if (user.banned) {
                this.router.navigate(['/banned']).then(r =>
                  console.log('access denied.'));
                this.toasterService.pop('error', 'Access Denied', 'You\'ve been banned');
              }
            }
          );
        }
      })
    );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
