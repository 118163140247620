import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CustomValidator } from '../../../form-validators/authentication.validator';
import { ToasterService } from 'angular2-toaster';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  // toastr
  private toasterService: ToasterService;

  loginForm: FormGroup; // init login form for users login details. Type of form group


  constructor(
    public auth: AuthService,
    private router: Router,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    toasterService: ToasterService,
  ) {
    this.toasterService = toasterService;
    this.createLoginForm();
  }

  ngOnInit(): void {

  }

  /**
   * Builds the reactive form with all of the validation
   */
  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['',
        [Validators.required, Validators.email],
        [CustomValidator.emailLogin(this.afs)]// checks if email exists in the database
      ],
      password: ['',
        [Validators.required, Validators.minLength(6)]]
    });
  }

  /**
   * Validates the form and if valid, passes the data to the auth service for signing user in with email and password
   */
  async onSubmit() {

    if (this.loginForm.valid) { // check if the form is valid
      const formData = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      };
      // provide credentials for email login service
      await this.auth.emailSignIn(formData.email, formData.password);
    } else {  // if the user tries entering nothing
      this.showToast(
        'error',
        `Error logging in`,
        `Please fill out all fields correctly`);
    }

  }

  /**
   * return: value of email from form group
   */
  get email() {
    return this.loginForm.controls.email;
  }

  /**
   * return: value of password from form group
   */
  get password() {
    return this.loginForm.controls.password;
  }

  /**
   * Displays a toast message
   * @param type the type of toast to display, success/error/waning/info/primary
   * @param title the title of the toast
   * @param body the message body of the toast
   */
  showToast(type, title: string, body: string) {
    this.toasterService.pop(type, title, body);
  }


}


