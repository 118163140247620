export interface User {
	// describe the shape of data to be saved in users collection
	activeStatus: boolean;
	biography: string;
	banned: boolean;
	email: string;
	firstName: string;
	lastName: string;
	linkedIn: string;
	city: { name: string; latitude?: string; longitude?: string };
	country: {
		phoneCode: string;
		currency?: string;
		isoCode?: string;
		latitude: string;
		longitude: string;
		name: string;
		flag: string;
	};
	organization: { name: string; ref: string };
	supervisor: Supervisor;
	permissions: {
		candidate: boolean;
		organizationAdmin: boolean;
		superAdmin: boolean;
		supervisor: boolean;
	};
	phoneNumber: number;
	projectsApplied: Array<ProjectsAppliedInterface>;
	ownedProjects: Array<ProjectsOwnedInterface>;
	topSkills: Array<string>;
	roles: Array<string>;
	uid: string;
	username: string;
	status?: string;
}

export enum SupervisorStatus {
	pending = 'pending',
	approved = 'approved',
	none = 'none',
}

interface Supervisor {
	name: string;
	ref: string;
	email: string;
	status: SupervisorStatus;
}

export interface ProjectsAppliedInterface {
	name: string;
	ref: string;
}

export interface ProjectsOwnedInterface {
	name: string;
	ref: string;
}
