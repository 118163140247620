<div class="modal-dialog modal-primary modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{type}}</h4>
      <button type="button" class="close" (click)="this.CloseModal.emit()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="ql-container ql-snow" style="border-width: 0;">
          <div class="ql-editor" [innerHTML]="legalInfo">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="this.CloseModal.emit()">Close</button>
    </div>
  </div><!-- end project modal-content -->
</div><!-- end project modal-dialog -->
