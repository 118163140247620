import {INavData} from '@coreui/angular';
// This class defines the left hand side menu bar options
export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Projects',
    url: '/projects',
    icon: 'icon-grid',
    // can add a badge to the nav link if need be
    // badge: {
    //   variant: 'danger',
    //   text: 'NEW',
    // },
  },
  {
    name: 'Candidates',
    url: '/candidates',
    icon: 'icon-people',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
  },
];

export const navItems_SuperAdmin: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Projects',
    url: '/projects',
    icon: 'icon-grid',
    // can add a badge to the nav link if need be
    // badge: {
    //   variant: 'danger',
    //   text: 'NEW',
    // },
  },
  {
    name: 'Candidates',
    url: '/candidates',
    icon: 'icon-people',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
  },
];


export const navItems_Candidate: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Projects',
    url: '/projects',
    icon: 'icon-grid',
    // can add a badge to the nav link if need be
    // badge: {
    //   variant: 'danger',
    //   text: 'NEW',
    // },
  },
  {
    name: 'Candidates',
    url: '/candidates',
    icon: 'icon-people',
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
  },
];

export const navItems_nonCandidate: INavData[] = [
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
  },
];

