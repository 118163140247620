import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CustomValidator } from '../../../form-validators/authentication.validator';
// Add the Performance Monitoring library
import 'firebase/performance';
import 'firebase/analytics';
import { ToasterService } from 'angular2-toaster';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AdminService } from '../../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { OrganizationInterface } from '../../../interfaces/organization-interface';
import { OrganizationsService } from '../../../services/organizations.service';
import { Observable } from 'rxjs';
import { registrantEmailDomainValidator } from '../../../form-validators/user-registration.directive';

@Component({
	selector: 'app-dashboard',
	templateUrl: 'register.component.html',
	styleUrls: ['../register-org/register-org.component.scss'],
	encapsulation: ViewEncapsulation.None, // allows multiple styles to exist. used for ng-select styles
})
export class RegisterComponent implements OnInit {
	@ViewChild('legalModal', { static: false }) public legalModal: ModalDirective;

	legal;
	doc;
	registerForm: FormGroup;
	organizations$: Observable<OrganizationInterface[]>;
	// toastr
	private toasterService: ToasterService;
	contractType: String;

	constructor(
		public authService: AuthService,
		private fb: FormBuilder,
		private afs: AngularFirestore,
		private adminService: AdminService,
		private orgService: OrganizationsService,
		private route: ActivatedRoute,
		toasterService: ToasterService
	) {
		this.toasterService = toasterService;
		this.createForm();
	}

	ngOnInit(): void {
		this.organizations$ = this.orgService.organizations$;
		this.route.queryParams.pipe(take(1)).subscribe((params) => {
			if (params['email']) {
				this.email.setValue(params['email']);
				this.email.setValidators(null);
				this.email.disable();
			}

			if (params['organizationId']) {
				this.organizations$.pipe(take(1)).subscribe({
					next: (organizations) => {
						const usersOrganization = organizations.filter(
							(organization) => organization.id === params['organizationId']
						)[0];
						console.log(usersOrganization);
						this.organization.patchValue(usersOrganization);
					},
				});
				this.organization.setValidators(null);
				this.registerForm.updateValueAndValidity();
				this.organization.disable();
			}
		});

		// get the legal Terms and Conditions for GHSS
		this.adminService.getLegalDocuments().then(async (legalDocs) => {
			await legalDocs.forEach((item) => {
				this.legal = item;
			});
		});
	}

	/**
	 * Builds the reactive form with all of the validation
	 */
	createForm() {
		this.registerForm = this.fb.group(
			{
				organization: [null, Validators.required],
				username: [
					'',
					[Validators.required, Validators.minLength(6)],
					[CustomValidator.usernameAvailability(this.afs)],
				],

				email: ['', [Validators.required, Validators.email], [CustomValidator.emailRegistration(this.afs)]],
				password: [
					'',
					[Validators.required, Validators.minLength(6), Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}')],
				],
				confirmPassword: ['', [Validators.required]],
				accept: [false, Validators.requiredTrue],
			},
			{
				validators: [CustomValidator.confirmPasswordValidator, registrantEmailDomainValidator()],
			}
		);
	}

	/**
	 * Validates the form and if valid, passes the data to the auth service for registering a new user with email and password
	 */
	async onSubmit() {
		if (this.registerForm.valid) {
			// check if the form is valid
			// provide credentials for email login service
			try {
				await this.authService.emailRegistration(
					this.email.value,
					this.password.value,
					this.username.value,
					this.organization.value
				);
				this.toasterService.pop('success', 'Registered', `Successfully registered as ${this.username.value}`);
			} catch (err) {
				this.toasterService.pop('error', `${err.code}`, `${err.message}`);
				console.error([`Error registering new user`, err.code, err.message]);
			}
			// if the user hasn't filled out correctly
		} else {
			this.toasterService.pop('error', `Error registering user`, `Please fill out form correctly`);
		}
	}

	/**
	 * return: value of organization from form group
	 */
	get organization() {
		return this.registerForm.controls.organization;
	}

	/**
	 * return: value of username from form group
	 */
	get username() {
		return this.registerForm.controls.username;
	}

	/**
	 * return: value of email from form group
	 */
	get email() {
		return this.registerForm.controls.email;
	}

	/**
	 * return: value of first password from form group
	 */
	get password() {
		return this.registerForm.controls.password;
	}

	/**
	 * return: value of second password for confirmation from form group
	 */
	get confirmPassword() {
		return this.registerForm.controls.confirmPassword;
	}

	/**
	 * return: true if terms of service checkbox has been selected
	 */
	get termsOfServiceChecked() {
		return this.registerForm.controls.accept;
	}
}
