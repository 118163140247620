import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';

declare var hbspt: any;

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
})
export class SupportModalComponent implements OnInit, AfterViewInit {
  @Output() CloseModal = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    hbspt.forms.create({
      region: 'na1',
      portalId: '20139240',
      formId: '529d2b09-4aca-4cf1-b049-804902ce32ec',
      target: '#hubspotForm'
    });
    window.scrollTo(0, 0);
  }

}
