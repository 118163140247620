export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBW2Njr9VAijn0fdiFMy4To8c_FFxPdRx4',
    authDomain: 'ghss-ng.firebaseapp.com',
    databaseURL: 'https://ghss-ng.firebaseio.com',
    projectId: 'ghss-ng',
    storageBucket: 'ghss-ng.appspot.com',
    messagingSenderId: '932588401901',
    appId: '1:932588401901:web:e522933041ab3d2c8c08e4',
    measurementId: 'G-2D7ZKX85GP'
  }
};
