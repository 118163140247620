import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, State, City } from 'country-state-city';
import { ICity, ICountry, IState } from 'country-state-city/dist/lib/interface';
import { OrganizationsService } from '../../../services/organizations.service';
import { OrganizationInterface, OrgApplication } from '../../../interfaces/organization-interface';
import {
	domainAvailability,
	domainNameValidator,
	orgAdminEmailValidator,
	pendingDomainAvailability,
} from '../../../form-validators/org-registration.directive';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

@Component({
	selector: 'app-register-org',
	templateUrl: './register-org.component.html',
	styleUrls: ['./register-org.component.scss'],
	encapsulation: ViewEncapsulation.None, // allows multiple styles to exist. used for ng-select styles
})
export class RegisterOrgComponent implements OnInit {
	loading = true;
	private toasterService: ToasterService;

	organizationApplicationForm: FormGroup;
	// ng2-select
	// location
	countries: ICountry[];
	states: IState[];
	statesLoading = false;
	hideStatesInput = true;
	cities: ICity[];
	citiesLoading = false;
	hideCitiesInput = true;

	orgDetails: OrganizationInterface = null;
	orgID: string;

	// Placeholders
	countryPlaceholder = 'eg. Switzerland';
	statePlaceholder = 'eg. Glarus';
	cityPlaceholder = 'eg. Geneva';
	shortNamePlaceholder = 'eg. MMV';

	constructor(
		private afs: AngularFirestore,
		private orgService: OrganizationsService,
		private router: Router,
		toasterService: ToasterService
	) {
		this.toasterService = toasterService;
	}

	ngOnInit(): void {
		this.countries = Country.getAllCountries(); // retrieve list of countries from Country State City module

		this.createForm();
		this.loading = false;
	}

	createForm() {
		this.organizationApplicationForm = new FormGroup(
			{
				website: new FormControl(
					'',
					[Validators.required, domainNameValidator()],
					[pendingDomainAvailability(this.afs), domainAvailability(this.afs)]
				),
				name: new FormControl('', Validators.required),
				shortName: new FormControl('', Validators.required),
				country: new FormControl(null, Validators.required),
				state: new FormControl(null, Validators.required),
				city: new FormControl(null, Validators.required),
				address1: new FormControl('', Validators.required),
				address2: new FormControl(''),
				postalCode: new FormControl('', Validators.required),
				orgAdminEmail: new FormControl('', [Validators.required, Validators.email]),
			},
			{ validators: orgAdminEmailValidator() }
		);
	}

	async submitApplication() {
		console.dir(this.organizationApplicationForm.value);
		const formData: Partial<OrgApplication> = {
			name: this.orgName.value,
			shortName: this.shortName.value,
			website: this.website.value,
			pendingAdmin: this.orgAdminEmail.value,
			status: 'pending',
			country: {
				name: this.country.value.name,
				phoneCode: this.country.value.phonecode,
				isoCode: this.country.value.isoCode,
				flag: this.country.value.isoCode.toLowerCase(),
				currency: this.country.value.currency,
				latitude: this.country.value.latitude,
				longitude: this.country.value.longitude,
			},
			state: this.state.value,
			city: this.city.value,
			address: {
				line1: this.address1.value,
				line2: this.address2.value,
				postalCode: this.postalCode.value,
			},
		};

		try {
			const docRef = await this.orgService.submitOrganizationApplication(formData);

			// send email to the admin letting them know that the application was received
			await this.orgService.sendOrgApplicationRevievedNotice(this.orgAdminEmail.value, this.orgName.value);

			// send an email to super admins letting them know that an application has been created
			this.orgService.sendOrgApplicationAdminNotice(this.orgAdminEmail.value, this.orgName.value, docRef.id);

			this.organizationApplicationForm.reset();
			await this.router.navigate(['/']);

			this.toasterService.pop(
				'success',
				`Application Submitted`,
				'You will be notified when your application is approved'
			);
		} catch (e) {
			console.log(`Error submitting org application:\n${e.message}`);
			this.toasterService.pop('error', 'Error submitting application', `${e.message}`);
		}
	}

	/**
	 * Retrieves the states of the selected country if exists. Calls getCities() if no states exist
	 */
	async getStates() {
		this.statesLoading = true;
		this.states = State.getStatesOfCountry(this.country.value?.isoCode);
		if (this.states.length > 0) {
			this.hideStatesInput = false;
			this.hideCitiesInput = true;
		} else {
			this.hideStatesInput = true;
			this.hideCitiesInput = false;
			this.state.clearValidators();
			this.state.disable;
			await this.getCitiesOfCountry();
		}
		this.statesLoading = false;
	}

	/**
	 * Retrieves the cities in the selected country
	 */
	async getCitiesOfCountry() {
		this.citiesLoading = true;
		this.cities = City.getCitiesOfCountry(this.country.value.isoCode);
		this.citiesLoading = false;
		this.hideCitiesInput = this.states?.length === 0;
		if (this.cities?.length === 0) {
			this.state.patchValue(this.country.value);
			this.city.patchValue(this.country.value);
		}
	}

	/**
	 * Retrieves the cities in the selected state
	 */
	async getCitiesOfState() {
		this.citiesLoading = true;
		this.cities = City.getCitiesOfState(this.country.value.isoCode, this.state.value?.isoCode);
		this.citiesLoading = false;
		if (this.cities?.length === 0) {
			await this.getCitiesOfCountry();
		} else {
			this.hideCitiesInput = false;
		}
	}

	/**
	 * @returns: Form control of the organization name from the organizationApplicationForm
	 */
	get orgName() {
		return this.organizationApplicationForm.get('name');
	}

	/**
	 * @returns: Form control of the organization name from the organizationApplicationForm
	 */
	get shortName() {
		return this.organizationApplicationForm.get('shortName');
	}

	/**
	 * @returns: Form control for website input from the organizationApplicationForm
	 */
	get website() {
		return this.organizationApplicationForm.get('website');
	}

	/**
	 * @returns: Form control of the organization name from the organizationApplicationForm
	 */
	get country() {
		return this.organizationApplicationForm.get('country');
	}

	/**
	 * @returns: Form control of the organization name from the organizationApplicationForm
	 */
	get state() {
		return this.organizationApplicationForm.get('state');
	}

	/**
	 * @returns: Form control of the organization name from the organizationApplicationForm
	 */
	get city() {
		return this.organizationApplicationForm.get('city');
	}

	get address1() {
		return this.organizationApplicationForm.get('address1');
	}

	get address2() {
		return this.organizationApplicationForm.get('address2');
	}

	get postalCode() {
		return this.organizationApplicationForm.get('postalCode');
	}

	get orgAdminEmail() {
		return this.organizationApplicationForm.get('orgAdminEmail');
	}
}
