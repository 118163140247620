<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
                  <h1>Pharos Community</h1>
                  <p class="text-muted">Sign In to your Pharos Community account</p>

                  <div class="form-group">    <!-- Email Input Form Group -->
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <label for="email"></label>
                      <input type="email"
                             id="email"
                             class="form-control"
                             placeholder="Email"
                             autocomplete="email"
                             formControlName="email"
                             required
                             [ngClass]="{
                             'is-valid': this.email.touched && this.email.valid,
                             'is-invalid': this.email.touched && !this.email.valid }"> <!-- end email input field -->

                      <div class="invalid-feedback" style="color:orangered" *ngIf="this.email.errors">
                        <div *ngIf="this.email.errors.emailExists ==false"> <!-- error for email taken -->
                          Can't seem to find email. <a routerLink="/register">Register Instead?</a>
                        </div>
                        <div *ngIf="this.email.errors.required"> <!-- error for email required -->
                          Email is required
                        </div>
                        <div *ngIf="this.email.errors.email">  <!-- error for invalid email entered -->
                          Please enter a valid email
                        </div>

                        <div *ngIf="email.pending">
                          searching...
                        </div>

                      </div> <!-- End invalid feedback for email -->

                    </div>

                  </div> <!-- End email form group input -->

                  <div class="form-group"> <!-- Password Form Group input -->
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>

                      <label for="password"></label>
                      <input type="password"
                             id="password"
                             class="form-control"
                             placeholder="Password"
                             autocomplete="current-password"
                             formControlName="password"
                             required
                             [ngClass]="{
                             'is-valid': this.password.valid && this.password.dirty,
                             'is-invalid': (this.password.dirty) && !this.password.valid }">  <!-- end password input field -->

                      <div class="invalid-feedback" *ngIf="this.password.errors" style="color:orangered">
                        <div *ngIf="this.password.errors.required">Password is required</div>
                      </div>  <!-- End invalid feedback for password -->

                    </div>
                  </div>  <!-- End password form group input -->


                  <div class="row">
                    <div class="col-6">
                      <button type="submit"
                              [disabled]="loginForm.invalid || loginForm.pristine"
                              class="btn btn-login text-white px-4">Login
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" routerLink="/forgot-password" class="btn text-password-reset btn-link px-0">Forgot password?</button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
            <div class="card text-white bg-pharos py-5">
              <div class="card-body text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Work on projects from all over the world or host your own projects
                    for others to work on with you</p>
                  <button type="button" routerLink="/register" class="btn btn-pharos active mt-3">Register Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
