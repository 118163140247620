<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto my-4">

            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()" class="needs-validation" novalidate>
                  <h1>Reset Password</h1>
                  <p class="text-muted">Enter your email address for GHSS</p>

                  <div class="form-group">    <!-- Email Input Form Group -->
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <label for="email"></label>
                      <input type="email"
                             id="email"
                             class="form-control"
                             placeholder="Email"
                             autocomplete="email"
                             formControlName="email"
                             required
                             [ngClass]="{
                             'is-valid': this.email.touched && this.email.valid,
                             'is-invalid': this.email.touched && !this.email.valid }"> <!-- end email input field -->

                      <div class="invalid-feedback" style="color:orangered" *ngIf="this.email.errors">
                        <div *ngIf="this.email.errors.emailExists ==false"> <!-- error for email taken -->
                          Can't seem to find email. <a routerLink="/register">Register Instead?</a>
                        </div>
                        <div *ngIf="this.email.errors.required"> <!-- error for email required -->
                          Email is required
                        </div>
                        <div *ngIf="this.email.errors.email">  <!-- error for invalid email entered -->
                          Please enter a valid email
                        </div>

                        <div *ngIf="email.pending">
                          searching...
                        </div>

                      </div> <!-- End invalid feedback for email -->

                    </div>

                  </div> <!-- End email form group input -->


                  <div class="row">
                    <div class="col-6">
                      <button type="submit"
                              [disabled]="passwordResetForm.invalid || passwordResetForm.pristine"
                              class="btn btn-primary px-4">Send Reset Email
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" routerLink="/login" class="btn btn-link px-0">Login Instead</button>
                    </div>
                  </div>

                </form>

              </div>
            </div>

        </div>
      </div>
    </div>
  </main>
</div>
