import {Component, OnInit, ViewChild} from '@angular/core';
import {navItems, navItems_SuperAdmin, navItems_nonCandidate, navItems_Candidate} from '../../_nav';
import {AuthService} from '../../services/auth.service';
import {ModalDirective} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public navItems;
  hover: boolean; // for the help & support link

  @ViewChild('supportModal', {static: false}) public supportModal: ModalDirective;


  constructor(public auth: AuthService) {
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async ngOnInit(): Promise<void> {
    const userData = await this.auth.getUserData();
    if (this.auth.isSuperAdmin(userData)) {
      this.navItems = navItems_SuperAdmin;
    } else {
      if (this.auth.isOrgAdmin(userData)) {
        this.navItems = navItems;
      } else {
        if (this.auth.isSupervisor(userData)) {
          this.navItems = navItems;
        } else {
          if (this.auth.isCandidate(userData)) {
            this.navItems = navItems_Candidate;
          } else {
            this.navItems = navItems_nonCandidate;
          }
          // if user is not yet a candidate

        }
      }
    }

  }

}
