import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { User } from '../interfaces/user-interface';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  candidatesCollection: AngularFirestoreCollection<User>; // passing the interface : Feedback


  constructor(private afs: AngularFirestore, private authService: AuthService) {
  }

  /**
   * returns all the users who have filled out their candidate profiles
   */
  async getAllCandidates() {
    // INIT CONNECTION TO FIRESTORE COLLECTION
    this.candidatesCollection = this.afs.collection<User>
      ('users', ref => {
        return ref.where('permissions.candidate', '==', true);
      }); // reference

    // SUBSCRIBE TO THE CHANGES
    return this.candidatesCollection
      .valueChanges({ idField: 'id' });
  }

  /**
   * returns all the users who have been contacted after project application approval
   */
  getContactedCandidates() {
    return this.afs.collectionGroup('candidates',
      ref => ref.where('contacted', '==', true)).valueChanges();
  }


  /**
   * get all the projects created and owned by the current user
   */
  async getProjectsOwned() {
    const userData = await this.authService.getUserData();
    return this.afs.collection<User>(
      `users/${userData.uid}/projectsOwned`)
      .valueChanges({ idField: 'id' });
  }

  /**
   * returns the document info of a specific user for the modal
   * @param {string} id document ID of the user
   */
  async getCandidateInfo(id: string) {
    return this.afs.doc<User>(`users/${id}`).valueChanges();
  }
}
