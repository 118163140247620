<div class="animated fadeIn">
	<main class="main d-flex flex-row justify-content-center">
		<div class="container p-0 m-0">
			<div class="col-md-6 p-0 mx-md-auto my-auto">
				<div class="card mt-5">
					<div class="card-body p-4">
						<form [formGroup]="registerForm" novalidate (ngSubmit)="onSubmit()" class="needs-validation">
							<h1>Register</h1>
							<p class="text-muted">Create your Pharos Community Account</p>

							<div class="form-group">
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="icon-user"></i></span>
									</div>
									<!-- col for country input -->
									<label for="organization"></label>
									<fieldset style="width: 85%">
										<ng-select
											[items]="organizations$ | async"
											[multiple]="false"
											[closeOnSelect]="true"
											[searchable]="true"
											id="organization"
											formControlName="organization"
											dropdownPosition="bottom"
											placeholder="Select Organization"
											bindLabel="name"
											required
											loadingText="Loading..."
											[ngClass]="{
												'is-valid': organization.valid && organization.touched,
												'is-invalid': !organization.valid && organization.touched
											}"
										>
										</ng-select>
									</fieldset>

									<div class="invalid-feedback" style="color: orangered" *ngIf="organization.errors">
										<!-- invalid feedback for organization -->
										<div *ngIf="organization.errors">
											<!-- selected country required error -->
											Please select an organization
										</div>
									</div>
									<!-- end invalid feedback -->
								</div>
								<!-- /col for organization -->
							</div>

							<div class="form-group">
								<!-- USERNAME -->
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="icon-user"></i></span>
									</div>
									<label for="username"></label>
									<input
										class="form-control"
										id="username"
										type="text"
										formControlName="username"
										placeholder="Username"
										autocomplete="username"
										required
										[ngClass]="{
											'is-valid': username.valid && username.touched,
											'is-invalid': (!username.valid && username.touched) || username.value.includes('@')
										}"
									/>

									<div
										class="invalid-feedback"
										style="color: orangered"
										*ngIf="username.errors || username.value.includes('@')"
									>
										<div *ngIf="username.value.includes('@')">
											<!-- username too short error -->
											Username cannot be an email
										</div>

										<div *ngIf="username.errors?.minlength">
											<!-- username too short error -->
											Username must be 6 characters or longer
										</div>

										<div *ngIf="username.errors?.usernameAvailable == false">
											<!-- username taken error -->
											Username has been taken. Please choose another
										</div>

										<div *ngIf="username.errors?.required">
											<!-- username required error -->
											Username is required
										</div>
									</div>
									<!-- end invalid feedback -->
								</div>
							</div>

							<div class="form-group">
								<!--  EMAIL INPUT -->
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text">@</span>
									</div>

									<label for="email"></label>
									<input
										type="text"
										id="email"
										class="form-control"
										placeholder="Email"
										autocomplete="email"
										formControlName="email"
										required
										[ngClass]="{
											'is-valid': email.touched && email.valid && !registerForm?.errors?.domainMismatch,
											'is-invalid': email.touched && (!email.valid || !!registerForm.errors?.domainMismatch)
										}"
									/>

									<div
										class="invalid-feedback"
										style="color: orangered"
										*ngIf="email.errors || registerForm.errors?.domainMismatch"
									>
										<div *ngIf="email.errors?.emailNew == false">
											<!-- error for email taken -->
											Email has been taken
											<a routerLink="/login">Login Instead?</a>
										</div>
										<div *ngIf="email.errors?.required">
											<!-- error for email required -->
											Email is required
										</div>
										<div *ngIf="registerForm.errors?.domainMismatch">
											<!-- error for email required -->
											Email must match organization domain
										</div>

										<div *ngIf="email.errors?.email">
											<!-- error for invalid email entered -->
											Please enter a valid email
										</div>
									</div>
								</div>
							</div>

							<!-- Form Group for user password form controls -->
							<div class="form-group">
								<!--  PASSWORD 1 INPUT -->
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="icon-lock"></i></span>
									</div>
									<label for="password"></label>
									<input
										type="password"
										id="password"
										class="form-control"
										placeholder="Password"
										autocomplete="new-password"
										formControlName="password"
										required
										[ngClass]="{
											'is-valid': password.valid && password.dirty,
											'is-invalid': password.dirty && !password.valid
										}"
									/>
									<!-- end password input field -->

									<div class="invalid-feedback" *ngIf="password.errors" style="color: orangered">
										<div *ngIf="password.errors.required">Password is required</div>
										<div *ngIf="password.errors.minlength">Password must be at least 6 characters</div>
										<div *ngIf="password.errors.pattern">
											Password must contain: numbers, uppercase and lowercase letters
										</div>
									</div>
									<!-- end invalid password feedback -->
								</div>
							</div>

							<div class="form-group">
								<!--  2nd PASSWORD INPUT FOR VALIDATION -->
								<div class="input-group mb-4">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="icon-lock"></i></span>
									</div>
									<label for="confirmPassword"></label>
									<input
										class="form-control"
										id="confirmPassword"
										type="password"
										formControlName="confirmPassword"
										placeholder="Confirm password"
										autocomplete="new-password"
										required
										[ngClass]="{
											'is-valid': !registerForm.errors?.passwordMismatch && confirmPassword.dirty,
											'is-invalid': confirmPassword.dirty && registerForm.errors?.passwordMismatch
										}"
									/>

									<div *ngIf="registerForm.errors?.passwordMismatch" class="invalid-feedback" style="color: orangered">
										<div>Passwords must match</div>
									</div>
								</div>
							</div>

							<div class="form-group">
								<!-- Check box for terms of service -->
								<div class="custom-control custom-checkbox">
									<input
										type="checkbox"
										class="custom-control-input"
										id="accept"
										formControlName="accept"
										[ngClass]="{
											'is-valid': termsOfServiceChecked.valid,
											'is-invalid': termsOfServiceChecked.dirty && !termsOfServiceChecked.valid
										}"
										required
									/>

									<label class="custom-control-label text-muted" for="accept">
										I accept the
										<a
											class="alert-link"
											style="cursor: pointer"
											(click)="contractType = 'Terms of Service'; doc = legal.termsOfService; legalModal.show()"
										>
											Terms of Service</a
										>
										and
										<a
											class="alert-link"
											style="cursor: pointer"
											(click)="contractType = 'Privacy Policy'; doc = legal.privacyPolicy; legalModal.show()"
										>
											Privacy Policy</a
										></label
									>
									<div class="invalid-feedback">
										<div>You have to accept our Terms and Conditions</div>
									</div>
								</div>
							</div>

							<button
								type="submit"
								[disabled]="registerForm.pristine || registerForm.invalid || username.value.includes('@')"
								class="btn btn-block btn-success"
							>
								Create Account
							</button>
						</form>
						<br />
						<p>Have an account? <a routerLink="/login">Login Here</a></p>
						<p>
							Can't find your organization?
							<a routerLink="/register/organization">Register Here</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</main>

	<!-- Modal for extra information when user clicks on a project -->
	<div
		bsModal
		#legalModal="bs-modal"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-labelledby="myModalLabel"
		aria-hidden="true"
	>
		<app-legal-modal [legalInfo]="doc" [type]="contractType" (CloseModal)="legalModal.hide()"> </app-legal-modal>
	</div>
	<!-- end project modal -->
</div>
