import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Ensure that org domain and user entered email domain matches */
export function registrantEmailDomainValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const orgDomain: string = control.get('organization')?.value?.website;
    const userEmailDomain = control.get('email')?.value?.split('@')[1];
    return userEmailDomain !== orgDomain
      ? { domainMismatch: { value: control.value?.website } }
      : null;
  };
}
