import { Injectable } from '@angular/core';
import {
	AngularFirestore,
	AngularFirestoreCollection,
	AngularFirestoreDocument,
	DocumentReference,
} from '@angular/fire/compat/firestore';
import { Observable, take } from 'rxjs';
import { OrganizationInterface, OrgApplication } from '../interfaces/organization-interface';
import { ToasterService } from 'angular2-toaster';
import { User } from '../interfaces/user-interface';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { arrayUnion, arrayRemove } from 'firebase/firestore';

@Injectable({
	providedIn: 'root',
})
export class OrganizationsService {
	// toastr
	private toasterService: ToasterService;
	organizationsCollection: AngularFirestoreCollection<OrganizationInterface>; // passing the interface : Feedback
	organizations$: Observable<OrganizationInterface[]>;

	orgApplicationCollection: AngularFirestoreCollection<Partial<OrganizationInterface> | OrgApplication>; // passing the interface

	constructor(private afs: AngularFirestore, private fns: AngularFireFunctions, toasterService: ToasterService) {
		this.toasterService = toasterService;
		try {
			// INIT CONNECTION TO FIRESTORE COLLECTION
			this.organizationsCollection = this.afs.collection<OrganizationInterface>('organizations', (ref) => {
				return ref;
			}); // reference

			// SUBSCRIBE TO THE CHANGES
			this.organizations$ = this.organizationsCollection.valueChanges({
				idField: 'id',
			});
		} catch (e) {
			console.log(['error getting organizations', e.code, e.message]);
		}
	}

	async getOrganizationData(orgID: string) {
		try {
			return this.afs.collection('organizations').doc<OrganizationInterface>(orgID).valueChanges();
		} catch (e) {
			console.log(['error getting organization details', e.code, e.message]);
		}
	}

	async updateOrganizationInformation(formData: Partial<OrganizationInterface>, orgID: string) {
		try {
			await this.afs.doc(`organizations/${orgID}`).update(formData);
			this.toasterService.pop('success', `Updated info for ${formData.name}`);
		} catch (e) {
			console.log(`Error updating org info:\n${e.message}`);
			this.toasterService.pop('error', 'Error updating Org Info', `${e.message}`);
		}
	}

	/**
	 * Adds the entered email into an array of invited supervisors for this org, then sends the user an email
	 * with a link to register
	 * When a new user signs up, a check for their email in this list will be ran and supervisor permissions will be
	 * given to the user if their email is present in the list.
	 * @param orgID The ID of the organization that this user belongs to.
	 * @param supervisorEmail The unique email address to be appended to the array
	 */
	async addInvitedSupervisor(orgID: string, supervisorEmail: string, candidateEmail?: string) {
		try {
			await this.afs.doc(`organizations/${orgID}`).update({
				invitedSupervisors: arrayUnion(supervisorEmail),
			});

			const emailBody = {
				to: supervisorEmail,
				message: {
					subject: `You've been invited by ${candidateEmail} to join the Pharo's Community as a Supervisor!`,
					html: `<p>Please register for an account by using <a
                 href="https://app.pharos.community/register?email=${supervisorEmail}">
                 This Link</a>.</p>
                 <p>You will be aded as a supervisor once you register and complete your profile.</p>
                 <p>Thanks!</p><p>The Pharos Community Team</p>`,
				},
			};

			if (candidateEmail) {
				emailBody['cc'] = candidateEmail;
			}

			await this.afs.collection('mail').add(emailBody);

			this.toasterService.pop(
				'success',
				`Invited ${supervisorEmail}`,
				`Permissions will be granted once they register`
			);
		} catch (e) {
			console.log(['error adding invited supervisor', e.code, e.message]);
			this.toasterService.pop('error', `Error inviting ${supervisorEmail}`, `${e.message}`);
		}
	}

	async addToApprovedSupervisors(userDoc: User | any, orgID: string) {
		try {
			// add the user to the list of approved supervisors of this organization
			await this.afs.doc(`organizations/${orgID}`).update({
				supervisors: arrayUnion({ name: userDoc.username, ref: userDoc.uid }),
			});

			// get the current permissions of the user as map to pass into update function
			const userPermissions = userDoc.permissions;

			// set supervisor permissions to true and maintain the rest
			userPermissions.supervisor = true;

			// update the users permissions
			this.afs
				.doc(`users/${userDoc.uid}`)
				.update({ permissions: userPermissions })
				.then(() => {
					this.toasterService.pop('success', `${userDoc.username} has been listed as a supervisor`);
				});
		} catch (e) {
			console.log(e);
			this.toasterService.pop('error', `Failed to add ${userDoc.username} as a supervisor`, e.message);
		}
	}

	/**
	 * Removes the entered email from the array of invited supervisors for this org.
	 * @param orgID The ID of the organization that this user belongs to.
	 * @param supervisorEmail The unique email address to be appended to the array
	 */
	removeInvitedSupervisor(orgID: string, supervisorEmail: string) {
		try {
			this.afs
				.doc(`organizations/${orgID}`)
				.update({
					invitedSupervisors: arrayRemove(supervisorEmail),
				})
				.then(() => {
					this.toasterService.pop('success', `${supervisorEmail} will no longer be listed as a supervisor`);
				});
		} catch (e) {
			console.log(['error removing invited supervisor', e.code, e.message]);
			this.toasterService.pop('error', `Error removing ${supervisorEmail}`, `${e.message}`);
		}
	}

	/**
	 * Removes the entered user ID from the array approved supervisors for this org
	 * and removes the supervisor permission from their account
	 * @param supervisor The Supervisor to be removed. Requires the following properties:
	 * <ul>
	 *  <li><b>ref</b>: The ID of the organization that this user belongs to.</li>
	 *  <li><b>userID</b>: The ID of user to be removed </li>
	 *  <li><b>index</b>: Need this to remove user with arrayRemove(index) </li>
	 *  <li><b>name</b>: The name of the supervisor used when displaying notifications </li>
	 *  </ul>
	 */
	async removeApprovedSupervisor(supervisor: { ref: string; orgID: string; index: number; name: string }) {
		try {
			// Need to get current approved supervisors to get the map of data associated with the supervisor at index
			this.afs
				.doc<AngularFirestoreDocument<OrganizationInterface>>(`organizations/${supervisor.orgID}`)
				.get()
				.subscribe(async (orgDoc) => {
					const orgData: any | OrganizationInterface = orgDoc.data();
					const approvedSupervisors: OrganizationInterface = orgData?.supervisors;
					this.afs
						.doc(`organizations/${supervisor.orgID}`)
						.update({
							supervisors: arrayRemove(approvedSupervisors[supervisor.index]),
						})
						.then(() => {
							let newSupervisor = {};
							let index = 0;
							let supervisorNotFound = true;
							while (supervisorNotFound) {
								if (approvedSupervisors[index].ref !== supervisor.ref) {
									newSupervisor = approvedSupervisors[index];
									supervisorNotFound = false;
									console.log('found new supervisor\n' + JSON.stringify(newSupervisor));
								}
								index++;
							}
							const callable = this.fns.httpsCallable('onSupervisorRemoval');
							callable({
								removedSupervisor: {
									name: supervisor.name,
									ref: supervisor.ref,
								},
								newSupervisor: newSupervisor,
							})
								.toPromise()
								.then((res) => {
									console.log(JSON.stringify(res));
								});
						});
				});

			// need to get the users document to have their current permissions
			this.afs
				.doc<AngularFirestoreDocument<User>>(`users/${supervisor.ref}`)
				.get()
				.subscribe(async (userDoc) => {
					if (!userDoc.exists) {
						await Promise.reject('User does not exist');
					}
					const userData: any | User = userDoc.data();

					const currentPermissions = userData.permissions;
					// update function cant update items in a map, only the whole map
					const newPermissions = {
						supervisor: false,
						candidate: currentPermissions.candidate,
						organizationAdmin: currentPermissions.organizationAdmin,
						superAdmin: currentPermissions.superAdmin,
					};
					// remove supervisor permissions for this user
					await this.afs.doc(`users/${supervisor.ref}`).update({
						permissions: newPermissions, // set the permissions map to the newPermissions
					});
				});

			this.toasterService.pop('success', `${supervisor.name}\'s permissions have been revoked`);
		} catch (e) {
			console.log(['error removing invited supervisor', e.code, e.message]);
			this.toasterService.pop('error', `Error removing ${supervisor.name}`, `${e.message}`);
		}
	}

	/**
	 *
	 * @param {Partial<OrgApplication>} formData
	 * @returns {Promise<DocumentReference>}
	 */
	async submitOrganizationApplication(formData: Partial<OrgApplication>): Promise<DocumentReference> {
		const collectionRef: AngularFirestoreCollection = this.afs.collection(`organizationApplications`);
		const res = await collectionRef.add(formData);
		return res;
	}

	/**
	 * Sends an email to the admin of the organization application letting them know that someone has made the application and
	 * nominated them as the admin.
	 * @param organizationAdminEmail
	 * @param organizationName
	 */
	async sendOrgApplicationRevievedNotice(organizationAdminEmail: string, organizationName: string) {
		const emailBody = {
			to: organizationAdminEmail,
			bcc: ['20139240@bcc.hubspot.com'],
			template: {
				name: `OrgApplicationRecieved`,
				data: {
					adminEmail: organizationAdminEmail,
					organizationName: organizationName,
				},
			},
		};

		await this.afs.collection('mail').add(emailBody);
	}

	/**
	 * Sends a notice to all super admins letting them know that there has been an organization application to pharos community
	 * @param organizationAdminEmail
	 * @param organizationName
	 * @param applicationId
	 */
	sendOrgApplicationAdminNotice(organizationAdminEmail: string, organizationName: string, applicationId: string) {
		const collectionRef: AngularFirestoreCollection<User> = this.afs.collection<User>('users', (ref) =>
			ref.where('permissions.superAdmin', '==', true)
		);
		const superAdmins$: Observable<User[]> = collectionRef.valueChanges().pipe(take(1));

		superAdmins$.subscribe({
			next: (users) => {
				const superAdmins: string[] = users.map((user) => user.email);
				const emailBody = {
					to: superAdmins,
					bcc: ['20139240@bcc.hubspot.com'],
					template: {
						name: `OrgApplicationAdminNotice`,
						data: {
							organizationAdminEmail: organizationAdminEmail,
							organizationName: organizationName,
							applicationId: applicationId,
						},
					},
				};
				this.afs.collection('mail').add(emailBody);
			},
		});
	}

	async getOrganizationApplications(): Promise<Observable<Partial<OrganizationInterface>[]>> {
		this.orgApplicationCollection = this.afs.collection<Partial<OrganizationInterface>>('organizationApplications'); // reference

		// SUBSCRIBE TO THE CHANGES
		return this.orgApplicationCollection.valueChanges({ idField: 'id' });
	}

	async getOrganizationApplication(applicationId: string): Promise<Observable<OrgApplication>> {
		return this.afs.doc<OrgApplication>(`organizationApplications/${applicationId}`).valueChanges({ idField: 'id' });
	}

	async approveOrganizationApplication(applicationInfo: OrgApplication) {
		applicationInfo.status = 'approved';

		// This will move the information to the organizations collection
		// The admin property will need to be updated once the admin signs up
		await this.afs.doc(`organizations/${applicationInfo.id}`).set(applicationInfo);

		await this.afs.doc(`organizationApplications/${applicationInfo.id}`).delete();
		this.toasterService.pop('success', `Approved ${applicationInfo.name}`);
	}

	/**
	 * Send an email to the organization admin letting them know that their application has been approved
	 * @param organizationAdminEmail
	 * @param organizationName
	 * @param organizationId
	 */
	sendOrgApplicationApprovalNotice(organizationAdminEmail: string, organizationId: string) {
		const emailBody = {
			to: organizationAdminEmail,
			bcc: ['20139240@bcc.hubspot.com'],
			template: {
				name: `OrgApplicationApprovalNotice`,
				data: {
					organizationAdminEmail,
					organizationId,
				},
			},
		};
		this.afs.collection('mail').add(emailBody);
	}
}
