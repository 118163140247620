import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../form-validators/authentication.validator';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-forggot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  passwordResetForm: FormGroup; // init login form for users login details. Type of form group
  private toasterService: ToasterService;

  private actionConfig = { url: 'https://ghss-ng.firebaseapp.com/login' };

  constructor(
    toasterService: ToasterService,
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) {
    this.createPasswordResetForm();
    this.toasterService = toasterService;

  }

  ngOnInit(): void {
  }

  /**
   * Builds the reactive form with all of the validation
   */
  createPasswordResetForm() {
    this.passwordResetForm = this.fb.group({
      email: ['',
        [Validators.required, Validators.email],
        [CustomValidator.emailLogin(this.afs)]// checks if email exists in the database
      ],
    });
  }

  /**
   * Validates the form and if valid, send a password reset email
   */
  async resetPassword() {

    if (this.passwordResetForm.valid) { // check if the form is valid

      try {
        // provide credentials for email login service
        await this.afAuth.sendPasswordResetEmail(this.email.value, this.actionConfig);
        this.toasterService.pop('success', 'Password Reset Email Sent',
          `Please follow the instructions sent to ${this.email.value} `);
      } catch (e) {
        this.toasterService.pop('error', 'Error sending Reset Email Sent',
          `${e.message}: ${e.code}`);
      }
    } else {  // if the user tries entering nothing
      this.toasterService.pop('error', 'Error sending Reset Email Sent',
        `Please ensure ${this.email.value} is the correct email`);
    }

  }

  /**
   * return: value of email from form group
   */
  get email() {
    return this.passwordResetForm.controls.email;
  }

}
