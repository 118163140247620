import { Component, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from '../../services/candidate.service';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { OrganizationInterface } from '../../interfaces/organization-interface';

@Component({
	selector: 'app-licenses',
	templateUrl: './licenses.component.html',
})
export class LicensesComponent implements OnInit {
	destroy = new Subject<any>();
	loading: boolean = true;
	license: string = null;
	orgName: string;
	org$: Observable<OrganizationInterface>;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private candidateService: CandidateService,
		private afs: AngularFirestore,
		private location: Location
	) {}

	ngOnInit(): void {
		this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe({
			next: async (params) => {
				if (params.type === 'orgCandidate' && params.id) {
					this.org$ = this.afs.doc<OrganizationInterface>(`organizations/${params.id}`).valueChanges();
					this.org$.pipe(take(1)).subscribe({
						next: (data) => {
							if (!data?.organizationCandidateContract) {
								this.router.navigateByUrl('/404');
							} else {
								this.orgName = data.name;
								this.license = data.organizationCandidateContract;
								this.loading = false;
							}
						},
						error: (error) => {
							console.log(`error: ${JSON.stringify(error)}`);
							this.router.navigateByUrl('/404').then(() => console.log(error));
						},
					});
				}
			},
			error: (error) => {
				console.log(`error: ${JSON.stringify(error)}`);
				this.router.navigateByUrl('/404').then(() => console.log(error));
			},
		});
	}

	closeModal() {
		console.log('routing back');

		this.location.back();
	}
}
