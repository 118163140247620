import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-legal-modal[legalInfo][CloseModal][type]',
  templateUrl: './legal-modal.component.html',
})
export class LegalModalComponent implements OnInit {

  @Input() legalInfo: { privacyPolicy: string, termsOfService: string };
  @Input() type: string;
  @Output() CloseModal = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
